import React from 'react';
import { fetchOrders, fetchLastActivity } from '../api';
import { useUser } from './user';
import { useLoading } from './loading';
import useQuery, { QUERY_NAV, QUERY_PAGE, QUERY_TXT, QUERY_STATUS, QUERY_DATE } from '../hooks/useQuery';
import { NAV_ALL, NAV_LAST_ACTIONS, NAV_MISSING ,NAV_TO_COLLECTION } from '../components/Nav';
import { STATUS_LIKUT, STATUS_MISSING_STOCK } from "../components/Status";

export const OrdersContext = React.createContext();
export const useOrders = () => React.useContext(OrdersContext);

export default function OrdersProvider({children}){
  const [ orders, setOrders ] = React.useState([]);
  const { user } = useUser();
  const { setGlobalLoading } = useLoading();
  const { query } = useQuery();
  const [ perPage, setPerPage ] = React.useState(20);

  React.useEffect( () => {
    const status=query.get(QUERY_STATUS);
    const page=query.get(QUERY_PAGE);
    const nav=query.get(QUERY_NAV);
    const txt=query.get(QUERY_TXT);
    switch (nav) {
      case NAV_ALL:
        getOrders({
          status, 
          page, 
          all: txt!=='' ? 0 : 1,
          txt
        });
        break;
      case NAV_TO_COLLECTION:
        getOrders({status:STATUS_LIKUT, perPage:999, all:0}); 
        break;
      case NAV_MISSING:
        getOrders({status:STATUS_MISSING_STOCK, perPage:999, all:0});
        break;
      case NAV_LAST_ACTIONS:
        getLastActivity();
        break;
      default:

        break;
    }
  },[ query.get(QUERY_NAV) ])

  const getLastActivity = async () => {
    try{
      setGlobalLoading(true);
      const res = await fetchLastActivity(user.user_id);
      if(res.status==='success')
        setOrders(res.data)
    }
    catch(err){
      console.error(err);
    }
    setGlobalLoading(false);
  }

  const getOrders = async ( {status=null, txt=null, page=1, date=null }) => {
    try{
      setGlobalLoading(true);
      const res = await fetchOrders({userId:user.user_id, status, txt, page, perPage, date});

      if(res.status==='success'){
        setOrders({...res.data})
        setPerPage(res.data.per_page)
      }
    }
    catch(err){
      console.error(err);
    }
    setGlobalLoading(false);
  }

  const orderData=({cell, toggle, state=true})=>{
    //ID,customer_name,date,status,notes,total
    let sortOrders=[...orders.orders.sort((a, b) =>{
      if(toggle){
        if(a[cell] < b[cell])  return -1 
        if(a[cell] > b[cell])  return 1 
      }
      else{
        if(a[cell] < b[cell])  return 1 
        if(a[cell] > b[cell])  return -1 
      }
      return 0;
    })];
    setOrders(prev=>({...prev, orders: sortOrders }))
  }
  const fetchDataWithFilters=(obj)=>{
    const nav=query.get(QUERY_NAV);
    const status=query.get(QUERY_STATUS);
    const txt=query.get(QUERY_TXT);
    const _date=query.get(QUERY_DATE);
    const date=_date && _date!=null ? JSON.parse(_date) : null;
    getOrders({nav, status,  txt, all:0, date, ...obj });
  }
  return(
    <OrdersContext.Provider value={ {orders, perPage, orderData, fetchDataWithFilters} }>
      {children}
    </OrdersContext.Provider>
  )
}