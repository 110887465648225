import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { OrderNav } from '../components/Nav';
import { STATUS_LIKUT, STATUS_MISSING_STOCK } from "../components/Status";
import { NAV_MISSING, NAV_TO_COLLECTION } from "../components/Nav";

export const QUERY_NAV='nav';
export const QUERY_STATUS='status';
export const QUERY_PAGE='page';
export const QUERY_TXT='txt';
export const QUERY_DATE='date_range';


export const DefaultQuery={
  nav: OrderNav[0].name,
  page:1,
  status: -1,
  txt:'',
  date: null
}

export default function useQuery() {
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const getDefaultParams=( )=>{
    const params=new URLSearchParams(search);
    if(!params.get(QUERY_NAV) )
      params.set(QUERY_NAV, DefaultQuery.nav)

    if(!params.get(QUERY_PAGE) )
      params.set(QUERY_PAGE, DefaultQuery.page)

    if(!params.get(QUERY_STATUS) )
      params.set(QUERY_STATUS, DefaultQuery.status)

    // params.set(QUERY_TXT, DefaultQuery.txt);
    // params.set(QUERY_DATE, DefaultQuery.date);
    return params;
  }
  const setDefaultQuery = () => {
    const params=getDefaultParams();
    navigate(`/?${params.toString()}`);
  }
  const clearFilters=(clearStatus=false)=>{
    query.set(QUERY_PAGE, 1);
    query.set(QUERY_TXT, '');
    if(clearStatus)
      query.set(QUERY_STATUS, '-1');
    query.set(QUERY_DATE, null);
    navigate(`/?${query.toString()}`);
  }
  const setNav = (nav) => {
    query.set(QUERY_PAGE, 1);
    query.set(QUERY_TXT, '');
    if(nav === NAV_MISSING)
      query.set(QUERY_STATUS, STATUS_MISSING_STOCK);
    else if(nav === NAV_TO_COLLECTION)
      query.set(QUERY_STATUS, STATUS_LIKUT);
    else
      query.set(QUERY_STATUS, '-1');
    query.set(QUERY_DATE, null);

    query.set(QUERY_NAV,nav);
    navigate(`/?${query.toString()}`);
  }
  const setQuery=(key, val)=>{
    query.set(key,val)
    navigate(`/?${query.toString(key, val)}`);
  }

  return { query, setNav, setQuery, setDefaultQuery, clearFilters }
}




