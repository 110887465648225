import React from 'react';
import { Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useScreen } from '../../context/screen';

const Container = styled(Box)(({ theme }) => {
  const screen=useScreen();
  if(screen.isTablet){
    return{
      position: 'absolute',
      right:0,
      left: 0,
      bottom:0,
      top:0,
      background: theme.palette.background.paper,
    }
  }
  else{
    return{
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: 24,
    }
  }
});

export default function MyModal( { children, open, onClose, topPoss='50%' } ) {
  React.useEffect(()=>{
    const body=document.getElementsByTagName('body')[0];
    if(open)
      body.style.overflowY = "hidden";
    else
      body.style.overflowY = "auto";
    return ()=>{
      body.style.overflowY = "auto";
    }
  }, [open])
  const screen=useScreen();
  return (
    <div>
      <Modal 
        open={open} 
        onClose={onClose} 
        sx={(theme)=>({background:`${theme.palette.background.modal}db`})}
      >
        <Container sx={!screen.isTablet ? {top:topPoss} : {}}>
          { children }
        </Container>
      </Modal>
    </div>
  );
}
