import React from 'react';
import logoRegular from '../assets/logo_regular.png';


const Print = React.forwardRef(({children, onPrint}, ref) => {
  return(
    <>
    <div dir='rtl' ref={ref} className='receipt' style={{padding:8}}>
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:32}}>
        <img src={logoRegular} alt="" style={{maxWidth:300}} />
      </div>
      {children}
    </div>
    </>
  )
});

export default Print;