import { toFormatDateForApi } from './utils';

// const endPoint="https://www.sifreiorhachaim.co.il/bo_api/?act=likutim";	
const endPoint="https://stage-orhachaim.b-online.co.il/bo-api/?act=likutim";

function fetchData(method='GET', doAction, requestData={} ){
  return new Promise((resolve, reject) => {
    fetch(`${endPoint}&consumer_key=${process.env.REACT_APP_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_CONSUMER_SECRET}&do=${doAction}`,{
      method: method, 
      body: method==='GET' ? null : requestData
    })
    .then(response => response.json(requestData))
    .then(res=>resolve(res))
    .catch(err=>reject(err))
  })
}

export const fetchBarcode=(userId, orderId)=>{
  return new Promise((resolve, reject) => {
    fetch(`${endPoint}&consumer_key=${process.env.REACT_APP_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_CONSUMER_SECRET}&do=get_barcode_pdf&user_id=${userId}&order_id=${orderId}`,{
      method: 'GET', 
    })
    .then(response => response.blob())
    .then(res=>resolve(res))
    .catch(err=>reject(err))
  })
}
export const fetchUser = ( email, password ) => {
  let formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  return fetchData('POST', 'auth_user', formData);
}
export const fetchOrders = ( {userId, status=null, txt=null, page=3, perPage=6, all=1, date} ) => {
  let formData = new FormData();
  formData.append('user_id', userId);
  status && formData.append('status', status);
  txt && formData.append('search', txt);
  page && formData.append('current_page', page);
  formData.append('per_page', perPage);
  formData.append('all', all);
  if(date){
    formData.append('date_after', toFormatDateForApi(date.startDate));
    formData.append('date_before', toFormatDateForApi(date.endDate));
  }
  return fetchData('POST', 'get_orders', formData);
}
export const fetchOrder = (userId, orderId) => {
  return fetchData('GET', `get_order&user_id=${userId}&order_id=${orderId}`, {});
}
export const fetchLastActivity = ( userId ) => {
  return fetchData('GET', `get_last_activity&user_id=${userId}`, {});
}
export const fetchProduct=( userId, sku)=>{
  return fetchData('GET', `get_product&user_id=${userId}&product_id=${sku}`, {});
}
export const apiUpdateCustomer = (userId, orderId, customer) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',             orderId);
  formData.append( 'city',                 customer.city);
  formData.append( 'email',                customer.email);
  formData.append( 'entrance',             customer.entrance);
  formData.append( 'house_number',         customer.house_number);
  formData.append( 'first_name',           customer.first_name);
  formData.append( 'last_name',            customer.last_name);
  formData.append( 'phone',                customer.phone);
  formData.append( 'street_address',       customer.street_address);
  formData.append( 'street_number',        customer.street_number);

  return fetchData('POST', 'update_order_customer', formData);
}
export const apiUpdateLikutItem=( userId, itemId, cnt, reson=null)=>{
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'item_id',              itemId);
  formData.append( 'lukat',                cnt);
  if(reson)
    formData.append( 'reson',                reson);
  return fetchData('POST', 'set_item_lukat', formData);
}
export const fetchSearchProduct=( userId, search )=>{
  return fetchData('GET', `search_product&user_id=${userId}&search=${search}`, {});
}
export const apiSetLikutMode = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',              orderId);
  return fetchData('POST', 'set_likut_mode', formData);
}
export const apiSaveLikut = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',              orderId);
  return fetchData('POST', 'save_likut_order', formData);
}
export const apiAddProducts = ( userId, orderId, products ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',              orderId);
  formData.append( 'products',              JSON.stringify(products));
  return fetchData('POST', 'add_products_to_order', formData);
}
export const apiSaveLastActivity = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',              orderId);
  return fetchData('POST', 'save_order_activity', formData);
}
export const fetchCountOrders = ( userId ) => {
  return fetchData('GET', `get_count_orders&user_id=${userId}`, {});
}
export const apiSaveCompleteOrder = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',              orderId);
  return fetchData('POST', 'save_complete_order', formData);
}
export const fetchStoreList=( userId )=>{
  return fetchData('GET', `get_stores_list&user_id=${userId}`, {});
}
export const apiMoveToStore = ( userId, orderId, soreNumber ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',             orderId);
  formData.append( 'store_number',         soreNumber);
  return fetchData('POST', 'move_to_store', formData);
}
export const apiAddOrderNote = ( userId, orderId, productId, comment ) => {
  let formData = new FormData();
  formData.append( 'user_id',            userId);
  formData.append( 'order_id',           orderId);
  formData.append( 'product_id',         productId);
  formData.append( 'comment',            comment);
  return fetchData('POST', 'add_order_note', formData);
}
export const apiRefundOrder = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',              userId);
  formData.append( 'order_id',             orderId);
  return fetchData('POST', 'refund_order', formData);
}
export const apiPayLikutimOrder = ( userId, orderId ) => {
  let formData = new FormData();
  formData.append( 'user_id',            userId);
  formData.append( 'order_id',           orderId);
  return fetchData('POST', 'pay_likutim_order', formData);
}

