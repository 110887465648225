import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import { 
  fetchOrder, 
  apiUpdateCustomer, 
  apiUpdateLikutItem, 
  apiSetLikutMode,
  apiSaveLikut,
  apiAddProducts,
  apiSaveCompleteOrder,
  apiMoveToStore,
  apiAddOrderNote,
  apiRefundOrder
} from "../api";
import { useUser } from './user';
import { useLoading } from './loading';
import { useAlert } from './alert';
import useQuery from '../hooks/useQuery';
import SuccessLikutModal from '../components/Modals/SuccessLikutModal';
import { STATUS_PENDING_SHIPPING } from '../components/Status';
import { NAV_TO_COLLECTION } from '../components/Nav';
import Print from '../components/Print';
import { useReactToPrint } from 'react-to-print';
import DisplayPdf from '../components/DisplayPdf';

const OrderContext = React.createContext();

export const useOrder = () => React.useContext(OrderContext);

export default function OrderProvider({children}){
  const { user } = useUser();
  const { setNav } = useQuery();
  const [ order, setOrder ] = React.useState(null);
  const [ scanModal, setScanModal ] = React.useState(false);
  const [ likutMode, setLikutMode ] = React.useState(undefined);
  const [ itemEdit, setItemEdit ] = React.useState(null);
  const { setAlert } = useAlert();
  const { setGlobalLoading } = useLoading();
  const [ successModal, _setSuccessModal ] = React.useState(null) // status, continue
  const params = useParams();

  const printRef=React.useRef();
  const { search } = useLocation();

  React.useEffect(()=>{
    const _search = new URLSearchParams(search);
    if(_search && _search.get('success') && params?.id){
      setSuccessModal(params.id);
    }
  },[search])

  React.useEffect(()=>{
    if(params?.id)
      getOrder(params.id);
    if(likutMode!==undefined) {
      likutMode ? _setLikutMode() : saveLikut();
    }
  },[likutMode, params]);

  const TotalDefault=React.useMemo(()=>{
    if(order?.order)
      return parseFloat(order.order.total);
    return 0;
  },[order])

  const TotalLukat=React.useMemo(()=>{
    if(order?.order){
      const shipingTotal = parseFloat(order.order.shipping.total);
      return order.order.items.reduce((total, x) => {
        const lukat=x.lukat==="" ? 0 : parseInt(x.lukat);
        return ((parseInt(lukat)*parseFloat(x.price)) + total)
      }, shipingTotal);
    }
    return 0;
  },[order])

  const getOrder = async (id) => {
    try {
      setGlobalLoading(true);
      const res = await fetchOrder( user.user_id, id );
      if(res.status==='success'){
        setOrder(res.data);
      }
    } 
    catch (err) {
      console.error(err);
    }
    setGlobalLoading(false);
  }

  const updateCustomer = async (customer) => {
    setGlobalLoading(true);
    try{
      const res = await apiUpdateCustomer(user.user_id, order.order.ID, customer);
      if(res.status==='success'){
        setOrder(prev=>({
          ...prev,
          customer:{...customer}
        }));
        setAlert('success', 'העדכון בוצע');
      }
      else
        setAlert('error', 'העדכון נכשל')
    }
    catch(err){
      setAlert('error', 'העדכון נכשל')
      console.error(err);
    }
    setGlobalLoading(false);
  }

  const pushNote = async ( product, text ) => {
    try{
      setGlobalLoading(true);
      const res = await apiAddOrderNote(
        user.user_id,
        order.order.ID,
        product ? product.product_id : 0, 
        text
      );
      if(res.status === 'success'){
        setOrder(prev=>({
          ...prev,
          notes:[{
            author:`${user.first_name} ${user.last_name}`,
            date: new Date(),
            note_id: res.data.comment_id,
            product_name: product ? product.name : '',
            text:text
          }, 
          ...prev.notes]
        }))
      }
      else 
        setAlert('error',"ההודעה לא נשמרה","");
    }
    catch(err){
      console.error(err);
      setAlert('error',"ההודעה לא נשמרה","פנה למפתח");
    }
    setGlobalLoading(false);
  }

  const updateLukat = async (item, newCntLukat, reason) => {
    const updateItem={...item, lukat: newCntLukat, lukat_time:111}
    setGlobalLoading(true);
    setItemEdit(null);
    try{
      const res = await apiUpdateLikutItem(user.user_id, item.item_id, newCntLukat, reason );
      if(res.status === 'success'){
        setOrder( o => (
          {
            ...o, 
            order: {
              ...o.order, 
              items:[...o.order.items.map( x => (x.item_id+'')===(updateItem.item_id+'') ? updateItem : x )]
            }
          }
        ));
        setAlert('success', 'העדכון בוצע');
      }
      else
        setAlert('error', 'העדכון נכשל')
    }
    catch(err){
      setAlert('error', 'העדכון נכשל')
      console.error(err);
    }
    setGlobalLoading(false);
  }

  const _setLikutMode = async () => {
    setGlobalLoading(true)
    try{
      const res = await apiSetLikutMode(user.user_id, order.order.ID);
      console.log(res);
    } 
    catch(err){
      console.error(err);
    }   
    setGlobalLoading(false);
  }

  const saveLikut = async () => {
    setGlobalLoading(true)
    try{
      const res = await apiSaveLikut(user.user_id, order.order.ID);
      if(res.status === 'success'){
        setOrder( o => ({ ...o,  order: { ...o.order,  status: res.data.status}}));
        _setSuccessModal({
          status:res.data.status,
          continue:()=>{
            if(res.data.status === STATUS_PENDING_SHIPPING)
              setNav(NAV_TO_COLLECTION)
            _setSuccessModal(null) 
          }
        });
      }
      else 
        setAlert('error', res.message)
    } 
    catch(err){
      console.error(err);
      setAlert('error', JSON.stringify(err))
    }   
    setGlobalLoading(false);
  }

  const addItems = async (items) => {
    const _products=[...items.map(x=>({product_id:x.product_id, qty:x.qty}))]
    try{
      setGlobalLoading(false);
      const res = await apiAddProducts(user.user_id, order.order.ID, _products);
      if(res.status==='success'){
        setOrder( o => ({...o, order: {...o.order, items:[...res.data.items, ...o.order.items]} }));
      }
      else
        setAlert('error', res.message)
    }
    catch(err){
      
      console.error(err);
    }
    setGlobalLoading(false)
  }

  const setSuccessModal = async ( orderId ) => {
    setGlobalLoading(true)
    try{
      const res = await apiSaveCompleteOrder( user.user_id, orderId )
      if(res.status === 'success'){
        //setOrder( o => ({ ...o,  order: { ...o.order,  status: res.data.status}}));
        _setSuccessModal({
          status:res.data.status,
          continue:()=>{
            if(res.data.status === STATUS_PENDING_SHIPPING)
              setNav(NAV_TO_COLLECTION)
            _setSuccessModal(null) 
          }
        });
      }
    }
    catch(err){
      console.error(err)
    }
    setGlobalLoading(false)
  }

  const transferOrder = async (soreNumber) => {
    try{
      setGlobalLoading(true)
      const res = await apiMoveToStore( user.user_id, order.order.ID, soreNumber );
      if(res.status === 'success'){
        _setSuccessModal({
          status:res.data.status,
          continue:()=>{
            setNav(NAV_TO_COLLECTION)
            _setSuccessModal(null) 
          }
        });
      }
    }
    catch(err){
      console.error(err);
    }
    setGlobalLoading(false)
  }

  const handleOnPrint = useReactToPrint({
    content: () =>printRef.current,
    documentTitle: 'emp-data',
    onAfterPrint: ()=>console.log("print")
  })

  const onPay = async (offset) => {
    try{
      setGlobalLoading(true)
      let res=await apiRefundOrder(user.user_id, order.order.ID)
      if(res && res.status === 'success'){
        setSuccessModal(order.order.ID);   
      }
      else{
        setAlert('error', res.message)
      }
      setGlobalLoading(false);
    }
    catch(err){
      console.error(err);
      setGlobalLoading(false);
      setAlert('error', 'תקלה');
    }
  }  
  return(
    <OrderContext.Provider 
      value={{
        order, 
        updateCustomer, 
        pushNote,
        scanModal,
        setScanModal,
        likutMode, 
        setLikutMode,
        itemEdit,
        setItemEdit,
        updateLukat,
        addItems,
        TotalDefault,
        TotalLukat,
        setSuccessModal,
        transferOrder,
        handleOnPrint,
        onPay
      }}
    >
      <>
      { successModal ? 
      <SuccessLikutModal 
        open={Boolean(successModal)}
        status={ successModal }
        title="הפעולה בוצעה בהצלחה"
        onPrint={handleOnPrint}
      /> : null }
      {children} 
      {order?.order ? 
        <>
        <div style={{display:'none'}}>
          <Print ref={printRef} onPrint={handleOnPrint}>
            <div>
              <strong>ספרי אור החיים</strong><br />
               גוש עציון 7 5403007 גבעת שמואל<br />
               <div style={{fontWeight: 'bold',textAlign:'center'}}>מספר הזמנה: 
                  <span style={{fontWeight: 'normal',textAlign:'center'}}>
                    {order.order.ID}
                  </span>
                </div>
              <table>
                <tr>
                  <th>שם</th>
                  <th>מק"ט</th>
                  <th>מחיר</th>
                  <th>כמות</th>
                  <th>סה"כ</th>
                </tr>
                {order.order.items.map((item, i)=>{
                  return(
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.sku}</td>
                      <td>₪{item.price}</td>
                      <td>{item.qty}</td>
                      <td>₪{item.total}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td>{order.order.shipping.method_name}</td>
                  <td></td>
                  <td>₪{order.order.shipping.total}</td>
                  <td>1</td>
                  <td>₪{order.order.shipping.total}</td>
                </tr>
                <tr>
                  <td colSpan={4}></td>
                  <td><strong>שולם:</strong>&nbsp;&nbsp;₪{order.order.total}</td>
                </tr>
              </table>
              <br />
              <table>
                <tr>
                  <td>
                    <strong><u>פרטי משלוח:</u></strong><br />
                    <b>עבור:</b> {order.customer.first_name} {order.customer.first_name}<br />
                    <b>טלפון:</b> {order.customer.phone}<br />
                    <b>אימייל:</b> {order.customer.email}<br />
                    <b>כתובת:</b> {order.order.shipping.address}
                  </td>
                </tr>
                </table>
            </div>
           <div style={{margin:'64px auto', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <DisplayPdf 
              userId={user?.user_id}
              orderId={order?.order.ID}
              barcodeId={order.order?.barcode_id ? order.order.barcode_id : '41004639'}
            /> 
           </div>
          </Print>
        </div>
        </>
      : null}
     </>
    </OrderContext.Provider>
  )
}