import React from 'react';
import { styled } from '@mui/material/styles';
import AlertMui from '@mui/material/Alert';

const AlertContext = React.createContext();
export const useAlert = () => React.useContext(AlertContext);

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  display:'none',
  top: '5%',
  left: '43%',
  transform: 'translate(-50%, -50%)',
  zIndex: theme.zIndex.tooltip,
}));

const INDEXES={
  'error':0,
  'success':1,
  'info':2,
  'warning':3
}

export default function AlertProvider({children}){
  const alertRefs = React.useMemo(() => Array(4).fill(0).map( x => React.createRef()), []);
  const textRefs  = React.useMemo(() => Array(4).fill(0).map( x => React.createRef()), []);

  const setAlert=(type, text, timeOut=5000)=>{
    const keys=Object.keys(INDEXES);
    let flag=false;
    for(let i=0; i< keys.length; i++){
      if(keys[i]===type){
        flag=true;
        break;
      }
    }
    if(flag){
      if(alertRefs[INDEXES[type]] && alertRefs[INDEXES[type]]?.current){
        alertRefs[INDEXES[type]].current.style.display='block';
        textRefs[INDEXES[type]].current.innerText=text;
      }
      setTimeout(()=>close(), timeOut)
    }
    else
      console.error('ALERT type alert is invalid');
  }
  const close=()=>{
    alertRefs.forEach( r => {
      if(r && r?.current)
        r.current.style.display='none';
    });
    textRefs.forEach( r => {
      if(r && r?.current)
        r.current.innerText ='';
    });
  }
  return(
    <AlertContext.Provider value={{setAlert, close}}>
      <>
        {alertRefs.map((r,i)=>(
          <Container key={i+'-alert'} ref={r}>
            <AlertMui variant="filled" severity={Object.keys(INDEXES)[i]}>
              <span ref={textRefs[i]}></span>
            </AlertMui>
          </Container>
        ))}
        {children}
      </>
    </AlertContext.Provider>
  )
}