import React from 'react';
import useSize from '../hooks/useScreen';

const ScreenContext = React.createContext();

export const useScreen = () => React.useContext(ScreenContext);


export default function SizeProvider({children}){
  const screen=useSize();
  return(
    <ScreenContext.Provider value={screen}>
      {children}
    </ScreenContext.Provider>
  )
}