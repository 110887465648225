import * as React from 'react';
import { styled } from '@mui/material/styles';
import localStorage from '../localStorage/navMenu';
import MuiDrawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';
import Search from './OrderBar/Search';

import { Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import logoBig from '../assets/logo-main.svg';
import logoSmall from '../assets/logo-small.svg';
import cartIcon from '../assets/cart-icon.svg';
import boxIcon from '../assets/box-icon.svg';
import infoIcon from '../assets/info-icon.svg';
import bookIcon from '../assets/book-icon.svg';
import userIcon from '../assets/user-icon.svg';
import exitIcon from '../assets/exit-icon.svg';

import { useUser } from '../context/user';
import useQuery, { QUERY_DATE, QUERY_NAV } from '../hooks/useQuery';
import { useOrders } from '../context/orders';
import { QUERY_STATUS, QUERY_TXT, QUERY_PAGE } from '../hooks/useQuery';
import { fetchCountOrders } from '../api';
import { useScreen } from '../context/screen';


export const NAV_ALL='ALL';
export const NAV_TO_COLLECTION='TO_COLLECTION';
export const NAV_MISSING='MISSING';
export const NAV_LAST_ACTIONS='LAST_ACTIONS';

export const OrderNav=[
  {title: 'הזמנות לליקוט',  icon: boxIcon,  name: NAV_TO_COLLECTION },
  {title: 'הזמנות חסרות',   icon: infoIcon, name: NAV_MISSING },
  {title: 'כל ההזמנות',      icon: cartIcon, name: NAV_ALL },
  {title: 'פעילות אחרונה', icon: bookIcon, name: NAV_LAST_ACTIONS },
];

const maxWidthBigLogo=220;

const timeUpdate=1000*60*1

export default function MiniDrawer({ onSearch }) {
  const screen = useScreen();

  const [open, setOpen] = React.useState(()=>{
    const navStorage = localStorage.get();
    return navStorage ? true : false
  });
  const [ countOrders, setCountOrders ] = React.useState( { likut: 0, missing: 0 });

  const { user, logOut } = useUser();
  const { setNav, setQuery, query, clearFilters } = useQuery();
  const { fetchDataWithFilters, orders } = useOrders();

  React.useEffect(()=>{
    const handle=setInterval(()=>{
      getCountOrders()
    },[timeUpdate]);
    return ()=>{
      clearInterval(handle)
    }
  },[])
  React.useEffect(()=>{
    getCountOrders();
  },[orders])

  React.useEffect(()=>{
    localStorage.set(open);
  }, [open])

  const getCountOrders=()=>{
    fetchCountOrders(user.user_id)
    .then(res=>{
      if(res.status==='success'){
        setCountOrders(({...res.data }))
      }
    })
    .catch((err)=>console.error(err))
  }

  const handleOnNavigate=(navName)=>{
    if(query.get('nav') !== navName){
      setNav(navName)
    }
    else{
      const withStatus=Boolean(navName===NAV_ALL);
      clearFilters( withStatus );
      fetchDataWithFilters({date:null, page:1, txt:'',status: (withStatus ? '-1' : query.get(QUERY_STATUS))});
    }
  }
  const handleOnSearch=(txt) => {
    query.set(QUERY_TXT, txt)
    if(query.get(QUERY_NAV) === NAV_ALL){
      setQuery(QUERY_PAGE,1)
      fetchDataWithFilters({txt, page:1});
    }
    else{
      query.set(QUERY_PAGE, 1);
      query.set(QUERY_STATUS, '-1');
      query.set(QUERY_DATE, null);
      setQuery(QUERY_NAV,NAV_ALL)
    }
  };

  const renderBadge=(nav)=>{
    if(nav.name===NAV_MISSING && countOrders.missing!==0)
      return <MyBadge num={countOrders.missing}/>
    else if(nav.name===NAV_TO_COLLECTION && countOrders.likut!==0)
      return <MyBadge num={countOrders.likut}/>
    return null;
  }

  return (
    <Drawer 
      variant="permanent" 
      open={open} 
      anchor='right'
    >
      <DrawerHeader>
        <IconButton color="inherit" onClick={()=>setOpen(!open)} >
          {open ? <img src={logoBig} alt="" style={{maxWidth:maxWidthBigLogo}} /> :
          <img src={logoSmall} alt="" style={{width: 35}}/> }
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List open={open} withHover={true} >
        {OrderNav.map((nav, i) => (
          <ListItem 
            key={nav.title} 
            disablePadding 
            divider={i!==(OrderNav.length-1)}
          >
            <ListItemButton 
              onClick={() => handleOnNavigate(nav.name)} 
              sx={{position:'relative'}}
              selected={query.get('nav') === nav.name}
            >
              <ListItemIcon sx={{position:'relative'}}>
                {nav.icon  && <img src={nav.icon} alt="" width={22} /> }
                {!open ? renderBadge(nav) : null}
              </ListItemIcon>
              <ListItemText  sx={{ opacity: open ? 1 : 0, textAlign:'start'}} >
                <Typography sx={{position:'relative', display:'inline-block'}}>
                  {open ? renderBadge(nav) : null}
                  {nav.title}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List 
        open={open} 
        withHover={false} 
        sx={{position:'absolute', bottom:0, left:0, right:0}}
        disablePadding
      >
        {open && !screen.isTablet ? <ListItem sx={{ backgroundColor: 'rgba(174, 176, 179, 0.5)'}} divider >
        <ListItemButton >
           <div >
            <Typography
              sx={{
                display:'block', 
                textAlign:'right',
                fontSize:14,
                fontWeight:400,
                margin:'8px 0'
              }}
            >
              חיפוש הזמנה
            </Typography>
            <Search
              onSearch={(txt, field) => handleOnSearch(txt)}
              value={query.get(QUERY_TXT)}
            />
          </div>
          </ListItemButton > 
        </ListItem> : null }
        <ListItem sx={{ backgroundColor: 'rgba(174, 176, 179, 0.5)',padding:0}} divider >
          <ListItemButton style={{display:'flex', justifyContent:'flex-end'}}>
            <ListItemIcon>
              <img src={userIcon} alt="" width={22}/>
            </ListItemIcon>
            <ListItemText 
              primary={`${user.first_name} ${user.last_name}`}
              secondary={user.store_name}
              sx={{ opacity: open ? 1 : 0, textAlign:'start' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ backgroundColor: 'rgba(174, 176, 179, 0.5)',padding:0 }} >
          <ListItemButton onClick={logOut} style={{display:'flex', justifyContent:'flex-end'}}>
            <ListItemIcon>
              <img src={exitIcon} alt="" width={22}/>
            </ListItemIcon>
            <ListItemText 
              primary={'יציאה'}
              sx={{ opacity: open ? 1 : 0, textAlign:'start' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}

// STYLES

const drawerWidth = 320;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.primary.main,
  color:'#fff'
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  background: theme.palette.primary.main,
  color:'#fff'
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3,0),
}));
const Drawer = styled( MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    position:'relative',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const List = styled( MuiList, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'withHover' } )(
  ({ theme, open, withHover }) => ({
    '& .MuiListItemButton-root': {
      display: open ? 'inline-flex' : 'flex',
      minHeight: 48,
      justifyContent: open ? 'inherit' : 'center',
      paddingRight: open ? theme.spacing(7) : theme.spacing(2)
    },
    "& .MuiListItemIcon-root":{
      display:'flex',
      justifySelf:'center',
      minWidth: 0,
      margin: open ? theme.spacing(0,0,0,2) : theme.spacing(0,2,0,0),
      color:'#fff',
    },
    '& .MuiListItemText-secondary':{
      color:'#fff'
    },
    '& .MuiListItem-root': {
      display:'flex',
      justifyContent:'center', 
      alignItems:'center', 
      borderColor: theme.palette.primary.light,
      borderWidth:0.5,
      "&:hover": {
        color: withHover ? theme.palette.secondary.main : '#fff',
        "& .MuiListItemIcon-root": {
          color: withHover ? theme.palette.secondary.main : '#fff',
        }
      },
      '& .Mui-selected':{
        background: '#2E0820',
        color: theme.palette.secondary.main,
        "& .MuiListItemIcon-root": {
          color: theme.palette.secondary.main
        }
      }
    },
  })
);

const StyledBadge = styled('label')(({ theme }) => ({
  position:'absolute',
  left: -15,
  top: -8,
  color:'#fff',
  background: '#EA3A26',
  fontSize:14,
  width: 20,
  height:20,
  borderRadius:'50%',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  fontFamily: 'Assistant',
}));

const MyBadge=({num})=>{
  return(
    <StyledBadge>
      {num}
    </StyledBadge>
  )
}