import React from 'react';
import { TextField, Button, Container, Paper, Typography, Grid, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import logo from '../assets/logo-main.svg';
import { useUser } from '../context/user';
import { useLoading } from '../context/loading';

const useStyles = makeStyles( (theme) => ({
  login:{
    backgroundColor: theme.palette.primary.main,
  },
  box:{
    padding:theme.spacing(3, 8),
    maxWidth:520,
  },
}))

export default function LogIn(){
  const classes=useStyles();
  const [userName, setUserName ] = React.useState('');
  const [password, setPassword ] = React.useState('');

  const { logIn, errorAuth } = useUser();

  const { privateLoading } = useLoading();

  const handleSubmit=()=>{
    logIn(userName, password, false);
  }

  return (
    <div 
      className={classes.login}
      maxWidth="lg"
      style={{
        height:'100vh',
      }}
    >     
      <Grid container direction="column" >
        <Grid 
          item
          container
          direction="column"
          xs={12}
          marginY={10}
          alignItems='center'
        >
          <img src={logo} alt="" style={{maxWidth:270}}/>
        </Grid>
        <Grid item container direction="column" xs={12} alignItems='center'>
          <Paper className={classes.box} elevation={12}>
            <Typography 
              align='center' 
              variant='h4' 
              color='primary'
              fontWeight={800}
              marginBottom={4}
            >
              {"כניסה"}
            </Typography>
            {errorAuth && 
            <Alert variant="filled" severity="error">
              <Typography >{errorAuth}</Typography>
            </Alert>}
            <Grid item container direction="column" xs={12}>
              <TextField 
                type='email'
                size='small'
                placeholder='שם משתמש'
                variant="outlined" 
                onChange={(e)=>setUserName(e.target.value)}
                sx={{margin:'8px 0'}}
              />
              <TextField 
                type='password'
                size='small'
                variant="outlined" 
                placeholder='סיסמא'
                onChange={(e)=>setPassword(e.target.value)}
                sx={{margin:'8px 0'}}
              />
              <Button sx={{ marginBottom:1, textDecoration:'underline'}}>
                {"שכחתי סיסמא"}
              </Button>
              <LoadingButton 
                loading={privateLoading} 
                // variant="outlined"
                variant='contained' 
                color='primary' 
                size='large' 
                // fullWidth={true}
                onClick={handleSubmit}
              >
                כניסה
              </LoadingButton>
            </Grid>
          </Paper>
        </Grid>         
      </Grid>
    </div>
  );
}

