const NAME_ITEM="user";

const getUser=()=>{
  let user = window.localStorage.getItem(NAME_ITEM);
  return user ? JSON.parse(user) : null;
}

const setUser=(user)=>{
  window.localStorage.setItem( NAME_ITEM, JSON.stringify(user))
  return user;
};


const removeUser=()=>{
  window.localStorage.removeItem(NAME_ITEM)
  return null;
}


const userLocalStorage = {
  get: getUser,
  set: setUser,
  clear: removeUser,
}

export default userLocalStorage;