import React from 'react';
import { Button, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Modal from './Modal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Status, { STATUS_PENDING_SHIPPING } from '../Status';
import printerIcon from '../../assets/printer.svg';
import { useScreen } from '../../context/screen';

const H1 = styled(Typography)(({ theme }) => ({
  fontSize: '2.6rem',
  fontWeight: theme.typography.fontWeightBold,
  color:theme.palette.success.main,
}));

export default function SuccessLikutModal( { open,  onClose, status, title, onPrint } ) {
  const screen=useScreen();
  return (
    <Modal open={open} onClose={onClose} >
      <div style={{ width: (screen.isTablet ? '100vw' : '40vw'), display:'flex', justifyContent:'center'}}>
        <Stack 
          direction={{ xl:'column', lg: 'column', md: 'column', sm:'column', xs:'column'  }} 
          alignItems='center'
          spacing={{ lg: 1 }} 
          sx={ theme => ({
            padding: theme.spacing(3,8),
            display:'inline-flex'
          }) }
        >
          <CheckCircleOutlineIcon 
            sx={ theme => ({color:theme.palette.success.main, fontSize:70}) }
           />
          <H1 component="h1" align='center'>{title}</H1>
          <Typography align='center'>סטטוס הזמנה:</Typography>
          <Status 
            status={status?.status ? status?.status : ''}
            withDote={false}
            withColor={false}
            style={{
              display:'flex',
              justifyContent:'center',
              padding:'2px 42px'
            }}
            fontSize={'1.2rem'}
            fontWeight={700}
          />
          <Grid container justifyContent='center'>
          {status !== STATUS_PENDING_SHIPPING && 
            <Grid item lg={6} sx={ theme=>({
              display:'flex',
              justifyContent:'center',
              padding:theme.spacing(2)
            })}>
              <Button 
                variant='contained' 
                fullWidth
                onClick={ onPrint }
                size='large'
                sx={{padding:'16px 32px'}}
              >
                <img src={printerIcon} alt='' style={{ width:20, marginLeft:8 }} />
                <Typography component='span' sx={{ fontWeight:700, fontSize:18 }}>
                  הדפס
                </Typography>
              </Button>
            </Grid>}
            <Grid item lg={status === STATUS_PENDING_SHIPPING ? 6 : 12} sx={ theme=>({
              display:'flex',
              justifyContent:'center',
              padding:theme.spacing(2)
            })}>
              <Button 
                variant='contained' 
                fullWidth
                onClick={ status?.continue ? status.continue : ()=>void 0  }
                size='large'
                sx={{padding:'16px 32px'}}
              >
                <Typography component='span' sx={{ fontWeight:700, fontSize:18 }}>
                  המשך
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Modal>
  );
}