import React from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { fetchBarcode } from '../api';

export default function DisplayPdf({userId, orderId}) {
  const [barcode, setBarcode] = React.useState(null)
  React.useEffect(()=>{

    if(userId && orderId){
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      fetchBarcode(userId, orderId)
      .then((res)=>{
        const file = window.URL.createObjectURL(res);
        setBarcode(file)
      })
      .catch(err=>{
        console.log(err);
      })
    }
  },[userId, orderId])
  if(!barcode)
    return null;

  return (
    <Document
      options={{
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true
      }}
      file={`${barcode}`}
      noData={"Please select a document to Preview"}
    >
      <div style={{display:'flex', justifyContent:'center', width:'100vw'}}>
        <Page
          pageNumber={1}
          scale={1}
          rotate={0}
          className={"has-hover bordered-active"}
          width={350}
          renderAnnotationLayer={false}
        />
      </div>
    </Document>
  );
}
