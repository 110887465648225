import React from 'react'
import {BrowserRouter, Routes, Route } from "react-router-dom";
import OrdersProvider from './context/orders';
import OrderProvider from './context/order';

const Orders = React.lazy( () => import('./pages/Orders') );
const Order = React.lazy( () => import('./pages/Order') );


const OrdersWithProvider = () => (
  <Orders/>
)
const OrderWithProvider = () => (
  <OrderProvider>
    <Order/>
  </OrderProvider>
)


export default function Router(props){
  return(
    <BrowserRouter>
      <React.Suspense fallback={<p>טוען...</p>}>
        <OrdersProvider>
          <Routes>
            <Route index path="/" element={ <OrdersWithProvider/> } />
            <Route path="order/:id" element={ <OrderWithProvider />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>טעות בניתוב</p>
                </main>
              }
            />
          </Routes> 
        </OrdersProvider>
      </React.Suspense>
    </BrowserRouter>
  );
}