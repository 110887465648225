import React from 'react';
import { LoadingRef } from '../components/Loading';

const LoadingContext = React.createContext();

export const useLoading = () => React.useContext(LoadingContext);


export default function LoadingProvider({children}){

  const preloadRef = React.useRef(null);

  const [ loading, setLoading ] = React.useState( {
    privateLoading: false,
  });
  
  const setPrivateLoading=(toggle)=>{
    setLoading((prev)=>({
      ...prev,
      privateLoading: toggle
    }))
  }
  const setGlobalLoading=(toggle)=>{
    if(preloadRef && preloadRef.current){
      if(toggle){
        preloadRef.current.style.display='block';
      }
      else{
        preloadRef.current.style.display='none';
      }
    }
  }
  return(
    <LoadingContext.Provider value={{ 
      privateLoading: loading.privateLoading,
      globalLoading: loading.privateLoading,
      setPrivateLoading,
      setGlobalLoading 
    }}>
      <>
        <LoadingRef ref={preloadRef}/> 
        {children}
      </>
    </LoadingContext.Provider>
  )
}