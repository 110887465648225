const NAME_ITEM="nav-menu";

const get=()=>{
  let x= window.localStorage.getItem(NAME_ITEM);
  return x ? JSON.parse(x) : null;
}

const set=( toggle )=>{
  window.localStorage.setItem(NAME_ITEM, toggle);
};


const clear=()=>{
  window.localStorage.removeItem(NAME_ITEM);
}


const branchDeliveryStorage = {
  get,
  set,
  clear
}

export default branchDeliveryStorage;