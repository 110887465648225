import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import UserProvider, { UserContext } from './context/user';
import LoadingProvider from './context/loading';
import AlertProvider from './context/alert';
import ScreenProvider from './context/screen';
import Router from "./Router";
import LogIn from "./pages/LogIn";

const theme = createTheme({
  direction:'ltr',
  typography: {
    fontSize: 14,
    fontFamily: `"Assistant", sans-serif`,
    fontWeightBold:700,
    fontWeightLight:300,
    fontWeightRegular:400,
    fontWeightMedium:500
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 700,
      lg: 1000,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main:'#4e0e36',
      light:'#9e7c91'
    },
    secondary:{
      main:'#fa8817'
    },
    success:{
      main:'#238884',
      light:'#23888453'
    },
    error:{
      main:'#d32f2f',
      light:'#d32f2f53'
    },
    warning: {
      main:'#fa8817'
    },
    background:{
      section:'#EEEAEC',
      paper:'#FAFAFA',
      default:'#FFFFFF',
      modal:'#652e50'
    },
  },
  shape:{
    borderRadius: 6
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {

        },
        contained:{
          
        },
        sizeLarge:{
          padding:12,
        }
      }
    },
  }
  
});

export default function App() {


  return (
    <ThemeProvider theme={theme}>
    <ScreenProvider>
    <AlertProvider>
    <LoadingProvider>
      <UserProvider>
        <UserContext.Consumer>
        {( { user } ) => user ? <Router /> : <LogIn/> }
        </UserContext.Consumer>
      </UserProvider>
    </LoadingProvider>
    </AlertProvider>
    </ScreenProvider>
    </ThemeProvider>
  );
}

