export const toFormatDateForTable=(dateTime)=>{
  const d=new Date(dateTime);
  let _day=d.getDate();
  let _month=(parseInt(d.getMonth())+1);
  let _hour=d.getHours();
  let _min=d.getMinutes();

  const day=(_day > 9 ? _day : `0${_day}`) ;
  const month=(_month > 9 ? _month : `0${_month}`) ;
  const year=d.getFullYear();

  const hour=(_hour > 9 ? _hour : `0${_hour}`) ;
  const min=(_min > 9 ? _min : `0${_min}`) ;

  return `${hour}:${min} ${day}.${month}.${year}`;
}

export const toFormatDateForApi=(dateTime)=>{
  const d=new Date(dateTime);
  let _day=d.getDate();
  let _month=(parseInt(d.getMonth())+1);

  const day=(_day > 9 ? _day : `0${_day}`) ;
  const month=(_month > 9 ? _month : `0${_month}`) ;
  const year=d.getFullYear();

  return `${day}-${month}-${year}`;
}
export const toFormatDateTimeForApi=(dateTime)=>{
  const d=new Date(dateTime);
  let _day=d.getDate();
  let _month=(parseInt(d.getMonth())+1);
  let _hour=d.getHours();
  let _min=d.getMinutes();
  let _sec=d.getSeconds();

  const day=(_day > 9 ? _day : `0${_day}`) ;
  const month=(_month > 9 ? _month : `0${_month}`) ;
  const year=d.getFullYear();

  const hour=(_hour > 9 ? _hour : `0${_hour}`) ;
  const min=(_min > 9 ? _min : `0${_min}`) ;
  const sec=(_sec > 9 ? _sec : `0${_sec}`) ;

  return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
}