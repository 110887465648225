import React from 'react';
import { fetchUser } from '../api';
import { useLoading } from './loading';
import localStorage from '../localStorage/user'

export const UserContext = React.createContext();
export const useUser = () => React.useContext(UserContext);

export default function UserProvider({children}){
  const [ user, _setUser ] = React.useState(()=>{
    const u=localStorage.get();
    return u ? u : null
  });
  const [ errorAuth, setErrorAuth ] = React.useState('');
  const { setPrivateLoading, setGlobalLoading } = useLoading();
  
  
  const logOut=()=>{
    setErrorAuth('');
    localStorage.clear();
    _setUser(null);
  }

  const logIn = async ( email='test@test.com', password='123', withLoader=true ) => {
    try{
      withLoader ? setGlobalLoading(true) : setPrivateLoading(true);
      const res=await fetchUser(email, password);
      if(res.status === 'success'){
        _setUser(res.data)
        localStorage.set(res.data)
        setErrorAuth('');
      }
      else{
        setErrorAuth(res.message)
      }
      withLoader ? setGlobalLoading(false) : setPrivateLoading(false);
    }
    catch(err){
      console.error(err);
    }
  }
  return(
    <UserContext.Provider value={{ user, logIn, logOut, errorAuth }}>
      {children}
    </UserContext.Provider>
  )
}