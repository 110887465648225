import React from 'react'
import {  Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import searchIcon from '../../assets/search.svg'

const Input = styled('input')(({ theme }) => {
  return{
    color:'#000',
    border:'none',
    padding:theme.spacing(1),
    boxShadow:'none',
    outline:'none',
    fontSize: 18,
    fontWeight:600,
    width:'100%',
    background:'#fff',
    fontFamily: 'Assistant',
    '&::placeholder':{
      color:'#aaa'
    }
  }
});
const RootStyle = {
  background:'#fff',
  display:'flex',
  justifyContent:'space-between',
  border:'1px solid #000',
  paddingRight:12,
  borderRadius:12
};

const btnStyle={
  borderRadius:12,
  height:'100%',
  marginBottom:10,
  boxShadow:'-1px 0 0 #4e0e36'
}

export default function Search({ value, onSearch, onClear }){
  const [ txt, setTxt ] = React.useState('');
  React.useEffect(()=>setTxt(value ? value : ''),[value])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter'){
      onSearch(txt, 'all');
    }
  }
  return (
    <div style={RootStyle}>
      <div style={{width:'92%'}}>
        <Input 
          variant="outlined"
          placeholder='הקלידו כאן'
          size='small'
          onChange={(e)=>setTxt(e.target.value)}
          onKeyDown={handleKeyDown}
          value={txt}
        />
      </div>
      <div>
        <Button 
          fullWidth
          disableElevation
          disableRipple
          disableTouchRipple
          variant="contained"
          style={btnStyle}
          onClick={()=> onSearch(txt, 'all') }
        >
          { <img src={searchIcon} alt=''/> }
        </Button>
      </div>
    </div>
  );
}
