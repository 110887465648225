import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const STATUS_LIKUT='likut';
export const STATUS_LUKAT='lukat';
export const STATUS_MISSING_STOCK='missing-stock';
export const STATUS_PENDING='processing';
export const STATUS_COMPLETED="completed";
export const STATUS_PENDING_SHIPPING="pending-shipping";
export const STATUS_PENDING_LIKUT="pending-likut";



export const StatusOrders=[{
    key: '-1',
    des: 'סטטוס',
    color:'#2372BE'
  },
  {
    key: STATUS_PENDING_LIKUT,
    des: 'ממתין לליקוט',
    color:'#FA8817'
  },
   {
    key: STATUS_LIKUT,
    des: 'בליקוט',
    color:'#FFD500'
  },
  {
    key: STATUS_LUKAT,
    des: 'לוקט',
    color:'#2372BE'
  },
  {
    key: STATUS_MISSING_STOCK,
    des: 'הזמנה חסרה',
    color:'#EA3A26'
  },
  {
    key: STATUS_PENDING_SHIPPING,
    des: 'מוכן לשילוח',
    color:'#a52f79'
  },
  {
    key: 'shipping',
    des: 'יצא למשלוח',
    color:'#238884'
  },
  {
    key: STATUS_COMPLETED,
    des: 'הושלם',
    color:'#1bb46a'
  },
  {
    key: 'refunded',
    des: 'החזר כספי',
    color:'#AEB0B3'
  },
  {
    key: 'cancelled',
    des: 'בוטל',
    color:'#AEB0B3'
  },
  {
    key: 'pending',
    des: 'ממתין לתשלום',
    color:'#AEB0B3'
  },
  {
    key: STATUS_PENDING,
    des: 'בטיפול',
    color:'#5ecff4'
  },
  {
    key: 'on-hold',
    des: 'בהשהייה',
    color:'#AEB0B3'
  }
]

export const ArrayStatusDes=StatusOrders.map(x=>x.des);
export const getIndexStatus=(status)=>StatusOrders.findIndex( x => x.key===status)

const StatusContainer = styled('div', { shouldForwardProp: (prop) => prop !== '_color'  })
(({ theme, _color }) => ({
  color: '#000',
  display: 'flex',
  alignItems:'center',
  border:'1px solid '+_color,
  borderRadius: 30,
  padding: theme.spacing(1, 0.5),
  backgroundColor:_color+'26',
  width:'100%'
}));
const Dote = styled('span', { shouldForwardProp: (prop) => prop !== '_color' })
(({ theme, _color }) => ({
  width:12,
  height:12,
  borderRadius:'50%',
  backgroundColor:_color,
  margin:theme.spacing(0, 1)
}));
export default function Status({status, withDote=true, withColor=true, style=null, fontSize=16, fontWeight=400 }){
  
  const _color = withColor ?  StatusOrders.find(x=>x.key===status)?.color : '#777';
  const des = StatusOrders.find(x=>x.key===status)?.des
  return (
    <StatusContainer _color={_color} style={style} >
      {withDote && <Dote _color={_color}/> }
      <Typography component='span' sx={  ({
        fontSize:fontSize,
        fontWeight:fontWeight
      })}>
        {des}
      </Typography>
    </StatusContainer>
  )
}