import React from 'react'

function test() {
  if (typeof window === 'undefined') {
    return false;
  }
  return {
    width: window.screen.width,
    height: window.screen.height,
    isTablet:(window.screen.width < 500 || window.screen.height < 850),
    isStand: window.screen.width < 750
  };
}

export default function useScreen() {
  const [screen, setScreen] = React.useState(test);

  React.useEffect(() => {
    if (typeof window === 'undefined') 
      return;
    window.addEventListener('resize', () => setScreen(test()));
    return () => window.removeEventListener('resize', () => setScreen(test()));
  }, [])

  return screen;
}