import React from 'react';
import { Modal, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  display:'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-43%, -50%)',
  zIndex: parseInt(theme.zIndex.tooltip)+111
}));

export const LoadingRef = React.forwardRef((props, ref) => {
  return (
    <Modal
      keepMounted
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
      open={true}
      ref={ref}
      sx={theme=>({display:'none', zIndex:parseInt(theme.zIndex.tooltip)+100})}
      // onClose={handleClose}
    >
      <Container >
        <CircularProgress color='secondary' />
      </Container>
    </Modal>
  );
});
// export const PreloadRef = React.forwardRef((props, ref) => {
// 	return(
// 		<div id='app-preload' className="spinner-wrapper ref" ref={ref}>
// 			<div className='wait'>
// 				<p style={{fontSize:32, marginBottom:200}}>טוען נתונים...</p>
// 				<Loader />
// 			</div>
// 		</div>
// 	);
// });